import { html } from 'lit-element';
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

customElements.define(
  "stat-programs",
  class ProgramsStat extends DashboardBlock {
    #statDomain;
    #displayInterval;

    constructor() {
      super();
      this.displayIndex = 0;
      this.displayColumns = [
        'total_activated',
        'total_active'
      ];
      this.displayLabels = [
        'Total activated programs',
        'Total active programs'
      ];
      this.#statDomain = new StatisticsDomain();
      this.type = 'mixed';
    }

    static get properties() {
      return {
        ...super.properties,
        type: {type: String, attribute: true},
        size: {type: String, attribute: true},
      }
    }

    static get demoData() {
      return {
        total_activated: 24,
        total_active: 19,
      };
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = ProgramsStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the program stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        this.data = await this.#statDomain.programs(this.filter.organisationId);
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    get value() {
      if (! this.data) return undefined;
      if (this.type === 'mixed') return this.data[this.displayColumns[this.displayIndex]] ?? undefined;
      if (this.type === 'activated') return this.data?.total_activated ?? undefined;
      return null;
    }
    get label() {
      if (this.type === 'mixed') return this.displayLabels[this.displayIndex];
      if (this.type === 'activated') return 'Total activated programs';
    }

    connectedCallback() {
      super.connectedCallback();

      if (this.type === 'mixed') {
        this.#displayInterval = setInterval(() => {
          if (this.data !== undefined) {
            this.displayIndex = (this.displayIndex + 1) % this.displayColumns.length;
            this.requestUpdate();
          }
        }, 3000);
      }
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      if (this.#displayInterval) clearInterval(this.#displayInterval);
    }

    render() {
      return html`
        <comparison-stat
          score="${this.value ?? '?'}"
          ?loading="${this.value === undefined}"
          label="${this.label}"
          icon="calendar"
          prefix=""
          size=${this.size}
          color="red"
        ></comparison-stat>
      `;
    }
  }
);
