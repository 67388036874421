import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { links } from "./index";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";

customElements.define(
  "page-organisation-group-list",
  class PageOrganisationGroupList extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminListPage) {
    canEdit = true;
    canDestroy = true;
    canAdd = false;
    searchable = true;
    title = null;

    static get properties() {
      return {
        ...super.properties,
        organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      };
    }

    editUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/groups/${obj.id}`;
    }

    newUrl() {
      return `/tenants/organisations/${this.organisationId}/groups/new`;
    }

    destroyUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/groups/${obj.id}/destroy`;
    }

    viewUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/groups/${obj.id}/view`;
    }

    constructor() {
      super(new GenericDomain());
    }

    connectedCallback() {
      this.domain.setBaseUrl(`/organisations/${this.organisationId}/groups`);
      super.connectedCallback();
    }

    get columns() {
      return [
        {name: 'Name', field: 'name', sortable: true, filterable: false, searchable: true},
        {name: 'Created', field: 'created_at', sortable: true, render: dateTimeRenderer},
      ];
    }
  }
);
