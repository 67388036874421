import {RoleMixin} from "../../shared/pwa-page";
import {AdminListPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {QuestionnaireCategoryDomain} from "../../domain/questionnaire-category-domain";
import { GenericDomain } from "../../domain/generic-domain";
import { html } from "lit";

export class PageQuestionnaireAnswerList extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminListPage) {
  canEdit = false;
  canDestroy = false;
  canAdd = false;
  searchable = true;
  title = null;

  static get properties() {
    return {
      questionnaireId: { type: String, attribute: 'questionnaire-id' },
    };
  }

  constructor() {
    super(new GenericDomain());
  }

  connectedCallback() {
    super.connectedCallback();
    this.domain.setBaseUrl(`/questionnaires/${this.questionnaireId}/answers`);
  }

  get columns() {
    return [
      {name: 'Question', field: 'question.question', sortable: true, searchable: true, render: row => html`${row.question?.question}`},
      {name: 'Answer', field: 'question_type_option_weight', sortable: true, render: row => html`${row.question_type_option?.value} (${row.question_type_option?.weight})`},
      {name: 'Category', field: null, sortable: false, render: row => html`${row.question?.question_category?.name}`},
      {name: 'Secondary Category', field: null, sortable: false, render: row => html`${row.question?.secondary_question_category?.name ?? '-'}`},
    ];
  }
}
customElements.define('page-questionnaire-answer-list', PageQuestionnaireAnswerList);
