import { RoleMixin } from "../../shared/pwa-page";
import { AdminViewPage } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";
import { links } from "./index";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { createRef, ref } from "lit/directives/ref.js";
import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog";
import { AutoComplete } from "@qogni-technologies/design-system/src/components/base/form/auto-complete";

export class PageProgramManage extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminViewPage) {
  // title = 'Program Management';
  topLinks = links

  #manageDayRef = createRef();
  #addBlockPartInputRef = createRef();

  #dayDomain;
  #recipeDomain;
  #supplementDomain;
  #workoutDomain;
  #autocompleteCache = {
    recipe: [], workout: [], supplement: [],
  };

  get title() {
    return this.object ? `Manage '${this.object.name}'` : 'Program Management';
  }

  get properties() {
    return {
      ...super.properties,
      selectedDay: { type: Object },

      addBlockEntryType: { type: String },
      addBlockEntryBlockId: { type: String },
    }
  }

  constructor() {
    super(new GenericDomain('/programs'));
    this.#recipeDomain = new GenericDomain('/recipes');
    this.#supplementDomain = new GenericDomain('/supplements');
    this.#workoutDomain = new GenericDomain('/workouts');

    this.addBlockEntryType = this.addBlockEntryBlockId = null;
  }

  async connectedCallback() {
    this.#dayDomain = new GenericDomain(`/programs/${this.id}/days`);
    super.connectedCallback();
  }

  #changeDay(e) {
    e.preventDefault();

    const dayId = e.target.closest('a').dataset['id'] ?? 'new';
    this.selectedDay = this.object?.programdays?.filter((d) => d.id === dayId)[0] ?? 'new';
    if (this.selectedDay === 'new') this.#createDay();
    this.requestUpdate();
  }

  #createDay() {
    const lastDay = this.object.programdays[this.object.programdays.length - 1];
    const dayNumber = lastDay ? lastDay.day+1 : 1;

    Task.run(async () => {
      await this.#dayDomain.create({
        day: dayNumber,
      });
      await this.fetch();

      app.addToastMessage('New day has been added');
      this.selectedDay = this.object.programdays[this.object.programdays.length - 1];
      this.requestUpdate();
    }, {
      ghost: this.#manageDayRef.value
    })
  }

  #createDayBlock() {
    if (! this.selectedDay) return;

    const nextBlockName = `Block ${this.selectedDay.programblocks.length + 1}`;
    const nextBlockOrder = this.selectedDay.programblocks.length;

    Task.run(async () => {
      const domain = new GenericDomain(`/programs/${this.id}/days/${this.selectedDay.id}/blocks`);
      await domain.create({
        name: nextBlockName,
        order: nextBlockOrder,
      });
      await this.fetch();
      this.selectedDay = this.object.programdays.filter((day) => day.id === this.selectedDay.id)[0];

      app.addToastMessage('New day-block has been added');

      this.requestUpdate();
    }, {
      ghost: this.#manageDayRef.value
    })
  }

  async #deleteDay(e) {
    const result = await askConfirm({title: 'Are you sure you want to delete this day from the program?'});
    if (! result || ! this.selectedDay) return;

    await Task.run(async () => {
      await this.#dayDomain.destroy(this.selectedDay.id);
      app.addToastMessage('Day has been deleted');
      this.selectedDay = null;
      await this.fetch()
      this.requestUpdate();
    }, {
      ghost: this.#manageDayRef.value
    });
  }

  async #addBlockPart(e) {
    this.addBlockEntryType = e.target.dataset['type'];
    this.addBlockEntryBlockId = e.target.closest('[data-block-id]').dataset['blockId'];
    this.requestUpdate();
  }

  async #addBlockPartSubmit(e) {
    e.preventDefault();
    if (! this.selectedDay || ! this.addBlockEntryType || ! this.addBlockEntryBlockId) return;
    const selectedEntityId = this.#addBlockPartInputRef.value.dataset['id'];
    const selectedEntityType = this.addBlockEntryType;

    await Task.run(async () => {
      const blockDomain = new GenericDomain(`/programs/${this.id}/days/${this.selectedDay.id}/blocks`);
      if (['recipe', 'workout'].indexOf(selectedEntityType) !== -1) {
        const data = {};
        data[`${selectedEntityType}_id`] = selectedEntityId;
        await blockDomain.update(this.addBlockEntryBlockId, data);
      } else {
        // Add supplement(s).
        const data = {
          supplements: this.selectedDay.programblocks.filter((block) => block.id === this.addBlockEntryBlockId)[0].supplements.map((s) => {
            return {
              id: s.supplement_id,
              amount: s.amount
            };
          }),
        };
        data.supplements.push({
          id: selectedEntityId,
          amount: 1
        });
        await blockDomain.update(this.addBlockEntryBlockId, data);
      }

      await this.fetch();
      this.selectedDay = this.object.programdays.filter((day) => day.id === this.selectedDay.id)[0];
      this.addBlockEntryType = this.addBlockEntryBlockId = null;
      app.addToastMessage('Update successfully');
    }, {
      ghost: this.#manageDayRef.value
    });

    this.requestUpdate();
  }

  async #adjustSupplement(e) {
    if (! this.selectedDay) return;
    const blockId = e.target.closest('[data-block-id]').dataset['blockId'];
    const supplementId = e.target.closest('[data-supplement-id]').dataset['supplementId'];
    const amount = parseInt(e.target.closest('[data-current-amount]').dataset['currentAmount']);
    const newAmount = amount + (e.target.dataset['direction'] === 'up' ? 1 : -1);

    await Task.run(async () => {
      const blockDomain = new GenericDomain(`/programs/${this.id}/days/${this.selectedDay.id}/blocks`);

      // Update supplements.
      const data = {
        supplements: this.selectedDay.programblocks.filter((block) => block.id === blockId)[0].supplements.map((s) => {
          return {
            id: s.supplement_id,
            amount: s.supplement_id === supplementId ? newAmount : s.amount
          };
        }),
      };
      await blockDomain.update(blockId, data);

      await this.fetch();
      this.selectedDay = this.object.programdays.filter((day) => day.id === this.selectedDay.id)[0];
      this.addBlockEntryType = this.addBlockEntryBlockId = null;
      app.addToastMessage('Update successfully');
    }, {
      ghost: this.#manageDayRef.value
    });

    this.requestUpdate();
  }

  async #deleteDayBlockEntry(e) {
    if (! this.selectedDay) return;
    const blockId = e.target.closest('[data-block-id]').dataset['blockId'];
    const entityId = e.target.closest('[data-id]')?.dataset['id'] ?? null;
    const supplementId = e.target.closest('[data-supplement-id]')?.dataset['supplementId'] ?? null;
    const type = e.target.closest('[data-type]')?.dataset['type'] ?? null;

    const result = await askConfirm({title: `Are you sure you want to delete this ${type}?`, okText: 'Yes, delete', cancelText: 'No, cancel'});
    if (! result) return;

    const domain = new GenericDomain(`/programs/${this.id}/days/${this.selectedDay.id}/blocks`);

    await Task.run(async () => {
      if (type === 'supplement') {
        await domain.update(blockId, {
          supplements: this.selectedDay.programblocks
            .filter((block) => block.id === blockId)[0].supplements
            .filter((s) => s.supplement_id !== supplementId)
            .map((s) => {
              return {
                id: s.supplement_id,
                amount: s.supplement_id === supplementId ? newAmount : s.amount
              };
            }),
        })
      } else {
        await domain.update(blockId, {
          [`${type}_id`]: null
        });

      }
      app.addToastMessage('Update successfully');

      await this.fetch()
      this.selectedDay = this.object.programdays.filter((day) => day.id === this.selectedDay.id)[0];
      this.addBlockEntryType = this.addBlockEntryBlockId = null;
      this.requestUpdate();
    }, {
      ghost: this.#manageDayRef.value
    });
  }

  get addBlockEntryAutoComplete() {
    const autoComplete = {categories: {}};
    autoComplete['categories'][this.addBlockEntryType] = {
      sortIndex: 1,
      trigger: (options) => {
        return options.search.length >= 2;
      },
      action: (options) => {
        this.#addBlockPartInputRef.value.value = options.text;
        console.log('action', options);
      },
      getItems: async (options) => {
        let domain = this.#recipeDomain;
        if (this.addBlockEntryType === 'workout') domain = this.#workoutDomain;
        if (this.addBlockEntryType === 'supplement') domain = this.#supplementDomain;
        const results = await domain.list({
          filter: options.search
        });
        const computedData = this.addBlockEntryType === 'supplement' 
          ? results.data.filter(e => !this.selectedDay.programblocks.find(f => f.id === this.addBlockEntryBlockId).supplements.some(s => s.supplement.id === e.id)) 
          : results.data;
        
        return computedData.map((item) => {
          return {
            text: item.name,
            itemData: item,
            description: item.description ?? '',
            category: this.addBlockEntryType,
            icon: this.addBlockEntryType === 'recipe' ? 'food' : this.addBlockEntryType,
          };
        });
      }
    };
    return autoComplete;
  }

  renderSidebar() {
    return html`
      <section class="card">
        <program-sidebar>
          <a href="#" class="button tiny" @click="${this.#changeDay.bind(this)}">
            <svg-icon icon="plus" size="12px"></svg-icon> Day
          </a>
          <h2>Structure</h2>

          ${this.object?.programdays ? html`
            <ul class="program-structure">
              ${repeat(this.object.programdays, (day) => {
                return html`
                  <li>
                    <a href="#" data-id="${day.id}" data-day="${day.day}"
                       class="${this.selectedDay?.id === day.id ? 'active' : ''}"
                       @click="${this.#changeDay.bind(this)}">
                      <svg-icon icon="calendar" size="12px"></svg-icon>&nbsp;Day ${day.day}
                    </a>
                  </li>
                `;
              })}
            </ul>
          ` : nothing}
        </program-sidebar>
      </section>
    `;
  }

  renderDetail(_) {
    return html`
      <flex-container>
        <flex-item class="col-4">
          ${this.renderSidebar()}
        </flex-item>
        <flex-item class="col-8">
          <section class="card" ${ref(this.#manageDayRef)}>
            <flex-container>
              <flex-item class="col-8">
                <h2>Manage day</h2>
              </flex-item>
              <flex-item class="col-4 text-end">
                ${this.selectedDay ? html`
                  <button type="button" class="tiny" @click="${this.#deleteDay.bind(this)}">
                    <svg-icon icon="trash"></svg-icon> Delete day
                  </button>
                ` : nothing}
              </flex-item>
            </flex-container>

            ${this.selectedDay ? html`
              ${this.renderDay(this.selectedDay)}
            ` : html`
              <callout-card type="warning" title="Please first select a day on the left side">
              </callout-card>
            `}
          </section>
        </flex-item>
      </flex-container>
    `;
  }

  renderDay(day) {
    return html`
      <flex-container>
        <flex-item class="col-6">
          <h3>Day ${day.day}</h3>
        </flex-item>
        <flex-item class="col-6 text-end">
          <button type="button" class="small"
                  title="${day?.programblocks?.length >= 3 ? 'Max day-blocks is 3 (morning, lunch, afternoon)' : ''}"
                  @click="${this.#createDayBlock.bind(this)}"
                  ?disabled="${day?.programblocks?.length >= 3}">
            Add day-block
          </button>
        </flex-item>
      </flex-container>

      ${day?.programblocks ? repeat(day.programblocks, (block, idx) => html`
        <day-block>
          ${this.renderProgramBlock(day, block, idx)}
        </day-block>
      `
      ) : nothing}
    `;
  }

  renderProgramBlock(day, block, idx) {
    let name = "Morning";
    let color = "green";
    if (idx === 1) {
      name = "Lunch";
      color = "yellow";
    }
    if (idx === 2) {
      name = "Afternoon";
      color = "red";
    }
    const { recipe, supplements, workout } = block;
    const isAddingEntry = this.addBlockEntryType !== null && this.addBlockEntryBlockId === block.id;

    return html`
      <section class="" data-block-id="${block.id}" data-day-id="${day.id}">
        <h4 class="accent-ball ${color}">${name}</h4>
        ${recipe ? html`
          <day-entry data-type="recipe" data-id="${recipe.id}">
            <svg-icon icon="food"></svg-icon>
            <span>${recipe.name}</span>
            <day-entry-controls>
              <button type="button" class="tiny" @click=${this.#deleteDayBlockEntry.bind(this)}>
                <svg-icon icon="trash" class="me-none"></svg-icon>
              </button>
            </day-entry-controls>
          </day-entry>
          `
        : nothing}
        ${repeat(
      supplements,
      (supplement) => html`
        <day-entry data-type="supplement" data-current-amount="${supplement.amount}" data-supplement-id="${supplement.supplement_id}">
          <svg-icon icon="supplement"></svg-icon>
          <span>${supplement.supplement.name} (${supplement.amount}x)</span>
          <day-entry-controls>
            <button type="button" class="tiny outline"
                    @click=${this.#adjustSupplement.bind(this)}
                    ?disabled=${supplement.amount < 2}
                    data-direction="down"
                    >-1</button>
            <button type="button" class="tiny outline"
                    @click=${this.#adjustSupplement.bind(this)}
                    data-direction="up"
            >+1</button>
            <button type="button" class="tiny" @click=${this.#deleteDayBlockEntry.bind(this)}>
              <svg-icon icon="trash" size="16px" class="me-none"></svg-icon>
            </button>
          </day-entry-controls>
        </day-entry>
          `
    )}
        ${workout ? html`
            <day-entry data-type="workout" data-id="${workout.id}">
              <svg-icon icon="workout"></svg-icon>
              <span>${workout.name}</span>
              <day-entry-controls>
                <button type="button" class="tiny" @click=${this.#deleteDayBlockEntry.bind(this)}>
                  <svg-icon icon="trash" class="me-none"></svg-icon>
                </button>
              </day-entry-controls>
            </day-entry>
            ` : nothing}

        <day-entry data-empty ${isAddingEntry ? html`data-add-entry` : nothing}>
          ${isAddingEntry ? html`
            <div>
              <input
                type="search"
                required
                placeholder="Search for ${this.addBlockEntryType}..."
                value=""
                ${ref(this.#addBlockPartInputRef)}
                @focus=${(e) => AutoComplete.connect(e, this.addBlockEntryAutoComplete)}
                @input=${(e) => e.stopPropagation()}
                @result-selected=${(e) => {this.#addBlockPartInputRef.value.dataset['id'] = e.detail.itemData.id;}}
              />
            </div>

            <button type="button"
                    class="tiny"
                    @click="${this.#addBlockPartSubmit.bind(this)}">
              Add
            </button>

            <button type="button"
                    class="outline tiny"
                    @click="${() => {this.addBlockEntryType = null; this.addBlockEntryBlockId = null; this.requestUpdate()}}">
              Cancel
            </button>
          ` : html`
            <button type="button"
                    class="button outline tiny"
                    @click=${this.#addBlockPart.bind(this)}
                    ?disabled="${!! recipe}"
                    data-type="recipe">
              <svg-icon icon="food"></svg-icon> Add Recipe
            </button>
            <button type="button"
                    class="button outline tiny"
                    @click=${this.#addBlockPart.bind(this)}
                    ?disabled="${!! workout}"
                    data-type="workout">
              <svg-icon icon="workout"></svg-icon> Add Workout
            </button>
            <button type="button"
                    class="button outline tiny"
                    @click=${this.#addBlockPart.bind(this)}
                    data-type="supplement">
              <svg-icon icon="supplement"></svg-icon> Add Supplement
            </button>
          `}
        </day-entry>

      </section>
    `;
  }
}
