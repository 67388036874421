import { html, LitElement } from "lit";
import { until } from "lit/directives/until.js";

export class DashboardBlock extends LitElement {
  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.filter = {};
  }

  get loading() {
    return this.data === undefined;
  }

  async fetch(refresh = false) {

  }

  static get properties() {
    return {
      data: {type: Object, attribute: true},
      size: {type: String, attribute: false}, // 'small', 'wide', 'big', etc.
      filter: {type: Object, attribute: true},
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    await this.fetch();
  }

  renderLoading() {
    return html`
      <app-shimmer></app-shimmer>
    `;
  }
  renderBlock() {
    return html`Not implemented!`;
  }

  render() {
    if (this.data === undefined) {
      return this.renderLoading();
    }
    // Check if this.data is a promise object.
    if (this.data instanceof Promise) {
      const promise = this.data.then((data) => {
        return html`${this.renderBlock()}`;
      })
      return html`${until(promise, this.renderLoading())}`;
    }
    return this.renderBlock();
  }
}
