import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { StatisticsDomain } from "../../../domain/statistics-domain";

customElements.define(
  "stat-burnout",
  class BurnoutStat extends DashboardBlock {
    #statDomain;

    static get properties() {
      return {
        ...super.properties,
        width: {
          type: Number,
          attribute: true,
        },
        height: {
          type: Number,
          attribute: true,
        }
      };
    }

    static get demoData() {
      // Generate data from 6 months ago until now.
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth() - 6, 1);
      const end = new Date(now.getFullYear(), now.getMonth(), 1);
      const data = [];

      // Define the probabilities of getting a high or low score.
      const pResult = 0.3; // Probability of having a result per day.
      const pRed = 0.029; // Probability of having a red result.
      const pYellow = 0.05; // Probability of having a yellow result.

      // Loop for every day between start and end dates.
      let date = start;
      while (date < end) {
        date = new Date(date.setDate(date.getDate() + 1));

        // Generate a random number and check against pResult to determine if there's a result today.
        const random = Math.random();
        if (random < pResult) {
          let score;

          if (random < pRed) {

            score = Math.floor(Math.random() * 36) + 45; // High risk score between 45 and 80
          } else if (random < pRed + pYellow) {
            score = Math.floor(Math.random() * 21) + 25; // Generate a score between 25 and 45.
          } else {
            score = Math.floor(Math.random() * 26); // Generate a score between 0 and 25.
          }

          data.push({
            created_at: date.toISOString().split('T')[0],
            score: '' + score,
          });
        }
      }

      return data;
    }

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.graph = null;
    }

    async getOptions() {
      const series = [{
        name: 'High Risk',
        data: [],
      }, {
        name: 'Medium Risk',
        data: [],
      }, {
        name: 'Low Risk',
        data: [],
      }];

      // Fill data.
      this.data.forEach((dataPoint) => {
        const score = parseInt(dataPoint.score);
        const entry = [new Date(dataPoint.created_at), score];
        if (score >= 50)
          series[0].data.push(entry);
        else if (score > 25 && score < 50)
          series[1].data.push(entry);
        else
          series[2].data.push(entry);
      });

      return {
        series,
        chart: {
          height: this.height ?? '500px',
          type: 'scatter',
          zoom: {
            type: 'xy',
          }
        },
        colors: [
          '#d44f73',
          '#e8ab5d',
          '#49946a',
        ],
        dataLabels: {
          enabled: false
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {},
        legend: {
          markers: {
            strokeWidth: 1,
          },
        },
        markers: {
          shape: [
            'diamond',
            'triangle',
            'circle',
          ],
          size: 6,
          fillOpacity: 0.9,
          strokeColors: '#333',
          strokeWidth: 1,
        },
      }
    }

    connectedCallback() {
      super.connectedCallback();
      if (! this.data) this.fetch();
    }

    async fetch() {
      return Task.run(async() => {
        if (this.filter.hasOwnProperty('organisationId') && ! this.filter.organisationId) {
          this.data = BurnoutStat.demoData;
        } else {
          this.data = await this.#statDomain.burnout(this.filter?.organisationId ?? null);
        }
        this.requestUpdate();
      });
    }

    async initGraph(el) {
      if (! this.graph && this.data) {
        this.graph = new ApexCharts(
          el,
          await this.getOptions()
        );

        this.graph.render();
      }
    }

    renderLoading() {
      return html`
        <app-shimmer></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
      `;
    }

    renderBlock() {
      return html`
        <div ${ref(this.initGraph.bind(this))}></div>
      `;
    }
  }
);
