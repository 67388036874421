import {RoleMixin} from "../../shared/pwa-page";
import {AdminDestroyPage} from "../../shared/admin";
import {Session} from "../../shared/session";
import {RoleDomain} from "../../domain/role-domain";
import {html} from "lit";
import { GenericDomain } from "../../domain/generic-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

export class PageOrganisationGroupDestroy extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminDestroyPage) {
  title = 'Role';
  #organisationDomain;

  get redirect() {
    return '/tenants/organisations/' + this.organisationId + '/view';
  }

  static get properties() {
    return {
      ...super.properties,
      organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
      organisation: { type: Object },
    };
  }

  constructor() {
    super(new GenericDomain());
    this.#organisationDomain = new GenericDomain(`/organisations`);
  }

  async connectedCallback() {
    this.id = this.routeData.pathname.groups.groupId;
    super.connectedCallback();
    this.domain.setBaseUrl(`/organisations/${this.organisationId}/groups`);
  }

  async fetch(options = {}) {
    const fetchOrganisation = Task.run(async () => {
      this.organisation = (await this.#organisationDomain.show(this.organisationId))?.data;
    });

    return Promise.all([
      super.fetch(options),
      fetchOrganisation,
    ]);
  }
}
