import { RoleMixin } from "../../shared/pwa-page";
import { AdminViewPage, renderBreadcrumbs } from "../../shared/admin";
import { Session } from "../../shared/session";
import { links } from "./index";
import { GenericDomain } from "../../domain/generic-domain";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { until } from "lit/directives/until.js";
import { createRef, ref } from "lit/directives/ref.js";
import { LedgerAccountDomain } from "../../domain/ledger-account-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import "./organisation-location-list";
import "./organisation-location-edit";
import "./organisation-user-list";
import "./organisation-user-invite";
import "./organisation-group-list";

export class PageOrganisationView extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminViewPage) {
  title = 'Organisation Details';
  topLinks = links

  #ledgerAccountDomain;
  #currenciesDomain;

  #currencySelectRef = createRef();
  #amountInputRef = createRef();

  static get properties() {
    return {
      ...super.properties,
      ledgerAccounts: { type: Array },
      showAddBalance: { type: Boolean },
      currencyList: { type: Array },

      users: { type: Array },
      usersPagination: { type: Object },
      usersShow: { type: Boolean },

      admins: { type: Array },
      adminsPagination: { type: Object },
      adminsShow: { type: Boolean },

      locationsShow: { type: Boolean },

      groupsShow: { type: Boolean },

      departments: { type: Array },
      departmentsPagination: { type: Object },
      departmentsShow: { type: Boolean },
    };
  }

  constructor() {
    super(new GenericDomain('/organisations'));
    this.#currenciesDomain = new GenericDomain('/currencies');
    this.showAddBalance = false;
  }

  get retrieveCurrencies() {
    return new Promise(async (resolve, reject) => {
      if (! Array.isArray(this.currencyList)) {
        const response = await this.#currenciesDomain.list({per_page: 1000});
        this.currencyList = response.data;
      }
      return resolve(this.currencyList);
    });
  }

  get breadcrumbs() {
    return [{
      name: 'Home',
      link: '/',
    }, {
      name: 'Organisations',
      link: `/tenants/organisations`,
    }, {
      name: this.object?.name ?? 'Organisation Details',
      link: `/tenants/organisations/${this.id}/view`,
      active: true,
    }];
  }

  async connectedCallback() {
    this.#ledgerAccountDomain = new LedgerAccountDomain(this.id);
    super.connectedCallback();
  }

  get showFinancialStats() {
    return true;
  }

  async fetch(options = {}) {
    const retrieveFinancialStats = Task.run(async () => {
      if (! this.showFinancialStats) return;
      try {
        const response = await this.#ledgerAccountDomain.list();
        this.ledgerAccounts = response.data;
      } catch (e) {
        console.error(e);
        app.addToastMessage('Can not retrieve financial stats of organisation');
      }
    }, {
      ghost: this
    });

    return Promise.all([
      super.fetch(options),
      retrieveFinancialStats,
    ]);
  }

  toggleDetails(e) {
    if (e.target.dataset?.source === 'locations') {
      this.locationsShow = e.target.open;
    } else if (e.target.dataset?.source === 'groups') {
      this.groupsShow = e.target.open;
    } else if (e.target.dataset?.source === 'users') {
      this.usersShow = e.target.open;
    }
  }

  async orderTopUp(e) {
    return Task.run(async () => {
      const currencyId = this.#currencySelectRef.value?.value;
      const amount = this.#amountInputRef.value?.value;
      if (! currencyId) {
        app.addToastMessage('No currency selected');
        return;
      }
      if (! amount) {
        app.addToastMessage('No amount provided');
        return;
      }

      const response = await this.#ledgerAccountDomain.createOrder({
        currency_id: currencyId,
        amount: amount
      });

      window.location.href = response.data.stripe_url;
    }, {
      ghost: this
    });
  }

  async addPayment() {
    return Task.run(async () => {
      const currencyId = this.#currencySelectRef.value?.value;
      const amount = this.#amountInputRef.value?.value;
      if (! currencyId) {
        app.addToastMessage('No currency selected');
        return;
      }
      if (! amount) {
        app.addToastMessage('No amount provided');
        return;
      }

      await this.#ledgerAccountDomain.createTransaction({
        currency_id: currencyId,
        amount: amount
      });
      this.showAddBalance = false;
      setTimeout(async () => {
        await this.fetch();
      }, 1000)

    }, { ghost: this })
  }

  renderOrganisationDetails() {
    return html`
      <section class="card">
        <details class="simple nested" open @toggle=${this.toggleDetails.bind(this)} data-source="details">
          <summary>
            <h3>Organisation details</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
          <table>
            <tbody>
            <tr>
              <td class="bold">Name</td>
              <td>${this.object.name}</td>
            </tr>
            <tr>
              <td class="bold">Type</td>
              <td>${this.object.organisation_type?.name ?? '-'}</td>
            </tr>
            <tr>
              <td class="bold">Category</td>
              <td>${this.object.organisation_category?.name ?? '-'}</td>
            </tr>
            <tr>
              <td class="bold">VAT-number</td>
              <td>
                ${this.object.vat_number_verified_at ? html`
                  <span title="Verified at ${new Date(this.object.vat_number_verified_at).format({mode: 'format'})}">
                      <svg-icon icon="check" display="inline" color="green" size="11px" display="inline"></svg-icon>&nbsp;${this.object.vat_number}
                    </span>
                ` : html`
                  <svg-icon icon="close" display="inline" color="red" size="11px"></svg-icon>
                  ${this.object.vat_number}
                `}
              </td>
            </tr>
            <tr>
              <td class="bold">Address</td>
              <td>
                ${this.object.address_line_1}<br>
                ${this.object.address_line_2 ? html`${this.object.address_line_2}<br>` : nothing}
                ${this.object.postal_code}, ${this.object.city}<br>
                ${this.object.state ? html`${this.object.state}<br>` : nothing}
                ${this.object.country}
              </td>
            </tr>
            </tbody>
          </table>
          <a href="/tenants/organisations/${this.object.id}" class="button tiny wide">
            Edit details
          </a>
        </div>
        </details>
      </section>
    `;
  }

  renderLocations() {
    return html`
      <section class="card">
        <details class="simple nested" @toggle=${this.toggleDetails.bind(this)} data-source="locations">
          <summary>
            <h3>Locations</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
            ${this.locationsShow ? html`
              <page-organisation-location-list organisationid="${this.object.id}" page-size="10"></page-organisation-location-list>
            ` : nothing}
            <a href="/tenants/organisations/${this.object.id}/locations/new" class="button tiny wide">
              Create location
            </a>
          </div>
        </details>
      </section>
    `;
  }

  renderGroups() {
    return html`
      <section class="card">
        <details class="simple nested" @toggle=${this.toggleDetails.bind(this)} data-source="groups">
          <summary>
            <h3>Groups</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
            ${this.groupsShow ? html`
              <page-organisation-group-list organisationid="${this.object.id}" page-size="10"></page-organisation-group-list>
            ` : nothing}
            <a href="/tenants/organisations/${this.object.id}/groups/new" class="button tiny wide">
              Create group
            </a>
          </div>
        </details>
      </section>
    `;
  }

  renderFinancialStats() {
    const roles = app.session.roles ?? [];
    const isQogniSuperAdmin = roles.findIndex(i => i.name === Session.ROLE_QOGNI_ADMIN) > -1;

    return html`
      <section class="card">
        <details class="simple nested" open @toggle=${this.toggleDetails.bind(this)} data-source="details">
          <summary>
            <h3>Statistics</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
            <table>
              <tbody>
              <tr>
                <td class="bold">Current balance</td>
                <td>
                  ${this.ledgerAccounts && Array.isArray(this.ledgerAccounts) && this.ledgerAccounts.length > 0 ? html`
                    ${repeat(this.ledgerAccounts, (account) => html`
                      ${unsafeHTML(account.currency?.symbol ?? account.currency?.code ?? '?')}
                      ${new Intl.NumberFormat().format(account.balance)}<br>
                    `)}
                  ` : html`
                    <i>No accounts or balance found.</i>
                  `}
                </td>
              </tr>
              <tr>
                <td class="bold">Total users</td>
                <td>0</td>
              </tr>
              <tr>
                <td class="bold">Active users</td>
                <td>0</td>
              </tr>
              </tbody>
            </table>
            <flex-container breakpoint="tiny" class="mb-none">
              <flex-item class="col-6">
                <a class="button tiny wide" @click=${() => {this.showAddBalance = !this.showAddBalance}}>
                  Add balance
                </a>
              </flex-item>
              ${isQogniSuperAdmin
                ? html`
                  <flex-item class="col-6">
                    <a href="/tenants/organisations/${this.object.id}/ledger" class="button tiny wide">
                      Show transactions
                    </a>
                  </flex-item>
                `
                : nothing}
            </flex-container>
          </div>

          <section class="card" ?hidden=${!this.showAddBalance}>
            <h4>Add balance</h4>
            <x-form>
              <form>
                <section class="card">
                  <flex-container>
                    <flex-item class="col-6">
                      <label>
                        <span data-label>Currency</span>
                        <select name="currency" ${ref(this.#currencySelectRef)}>
                          ${until(this.retrieveCurrencies.then((currencies) => html`
                            ${repeat(currencies, (c) => html`
                              <option value="${c.id}">
                                ${c.name}
                              </option>
                            `)}
                          `), html`
                            <option disabled>Loading currencies...</option>
                          `)}
                        </select>
                      </label>
                    </flex-item>
                    <flex-item class="col-6">
                      <input type="number" placeholder="Amount" data-label="Amount" name="amount" ${ref(this.#amountInputRef)} />
                    </flex-item>
                    ${app.session.hasRole(Session.ROLE_QOGNI_ADMIN) ? html`
                      <flex-item class="col-6">
                        <button type="submit" class="wide" @click=${this.addPayment}>
                          Add as Qogni (no payment)
                        </button>
                      </flex-item>
                      <flex-item class="col-6">
                        <button type="submit" class="wide" @click=${this.orderTopUp.bind(this)}>
                          Create order + pay
                        </button>
                      </flex-item>
                    ` : html`
                      <flex-item class="col-12">
                        <button type="submit" class="wide" @click=${this.orderTopUp.bind(this)}>
                          Create order and continue to pay
                        </button>
                      </flex-item>
                    `}
                  </flex-container>
                </section>
              </form>
            </x-form>
          </section>
        </details>
      </section>
    `;
  }

  renderUsers() {
    return html`
      <section class="card">
        <details class="simple nested" @toggle=${this.toggleDetails.bind(this)} data-source="users">
          <summary>
            <h3>All Users</h3>
            <svg-icon icon="caret"></svg-icon>
          </summary>

          <div class="data-table">
            ${this.usersShow ? html`
              <page-organisation-user-list organisationid="${this.object.id}" page-size="10"></page-organisation-user-list>
            ` : nothing}
            <flex-container breakpoint="tiny" class="mb-none">
              <flex-item class="col-6">
                <a href="/tenants/organisations/${this.object.id}/users/import" class="button tiny wide">
                  Import users
                </a>
              </flex-item>
              <flex-item class="col-6">
                <a href="/tenants/organisations/${this.object.id}/users/invite" class="button tiny wide">
                  Invite user(s)
                </a>
              </flex-item>
            </flex-container>
          </div>
        </details>
      </section>
    `;
  }

  renderDetail(obj) {
    return html`
      ${renderBreadcrumbs(this.breadcrumbs)}

      <flex-container breakpoint="tiny">
        <flex-item class="col-6">
          ${this.renderOrganisationDetails()}
        </flex-item>
        ${this.showFinancialStats ? html`
          <flex-item class="col-6 grow-1">
            ${this.renderFinancialStats()}
          </flex-item>
        ` : nothing}
      </flex-container>

      ${this.renderLocations()}

      ${this.renderGroups()}

      ${this.renderUsers()}
    `;
  }
}
