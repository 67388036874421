import { html, nothing } from 'lit';
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { ref } from "lit/directives/ref.js";
import ApexCharts from "apexcharts";

customElements.define(
  "stat-fivepillar",
  class FivepillarStat extends DashboardBlock {
    #statDomain;

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.graph = null;
    }

    static get properties() {
      return {
        ...super.properties,
        size: {type: String, attribute: true},
      }
    }

    static get demoData() {
      return {
        count_v1: 0,
        count_v2: 15,
        pillars: [{
          pillar: 'energy',
          version: 2,
          score: 104,
          normalized: 0.62,
        }, {
          pillar: 'sleep',
          version: 2,
          score: 108,
          normalized: 0.75,
        }, {
          pillar: 'focus',
          version: 2,
          score: 100,
          normalized: 0.80,
        }, {
          pillar: 'stress_management',
          version: 2,
          score: 100,
          normalized: 0.50,
        }, {
          pillar: 'mental_resilience',
          version: 2,
          score: 100,
          normalized: 0.35,
        }]
      };
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = FivepillarStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the five-pillar stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        this.data = await this.#statDomain.fivePillar(this.filter.organisationId);
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    async getOptions() {
      const values = [];
      const labels = [];

      this.data.pillars.forEach((d) => {
        values.push(d.normalized * 100);
        let pillar = d.pillar.replace('_', ' ');
        pillar = pillar.charAt(0).toUpperCase() + pillar.slice(1);
        if (pillar.includes(' ')) pillar = pillar.split(' ');
        labels.push(pillar);
      });
      const colors = [
        '#d44f73',
        '#e8ab5d',
        '#49946a',
        '#2d9dc9',
        '#2b3a33',
        '#c0569b',
        '#f0c37e',
        '#66b181',
        '#4fb2d5',
        '#3c4a42',
      ];

      return {
        series: [{
          name: 'Average percentage',
          data: values,
        }],
        chart: {
          type: 'bar',
        },
        colors,
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
              fontSize: '12px'
            },
            rotate: -90,
          }
        }
      };
    }

    get value() {
      return null;
    }

    async initGraph(el) {
      if (! this.graph && this.data && (this.data.count_v1 >= 5 || this.data.count_v2 >= 5)) {
        this.graph = new ApexCharts(
          el,
          await this.getOptions()
        );
        this.graph.render();
      }
    }

    render() {
      return html`
        <h3>Qogni Core 5</h3>
        ${this.loading ? html`
          <app-shimmer class="image"></app-shimmer>
        ` : html`
          <div ${ref(this.initGraph.bind(this))}></div>
          ${! this.data || this.data.length === 0 || (this.data.count_v1 < 5 && this.data.count_v2 < 5) ? html`
          <i>No data available, not enough users that finished their checks.</i>
        ` : nothing}
        `}
      `;
    }
  }
);
