import { html } from 'lit-element';
import ApexCharts from 'apexcharts'
import { createRef, ref } from "lit/directives/ref.js";
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { nothing } from "lit";

customElements.define(
  "stat-calculator",
  class CalculatorStat extends DashboardBlock {
    #statDomain;
    #dailyCostRangeRef = createRef();
    #annualBudgetRangeRef = createRef();

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();

      this.annualBudgetUpperLimit = 34285; // todo dynamic from the server for organisation.
      this.annualBudget = 10000; // todo: same as above.

      this.dailyCostUpperLimit = 2000;
      this.dailyCost = 1000;

      this.exposedRisk = 968000;
      this.roiPrograms = 705;
      this.paybackPeriod = 52;
      this.businessRoi = 70584;
    }

    static get properties() {
      return {
        ...super.properties,
        dailyCost: {type: Number},
        annualBudget: {type: Number},
        exposedRisk: {type: Number},
        roiPrograms: {type: Number},
        paybackPeriod: {type: Number},
        businessRoi: {type: Number},
      }
    }

    static get demoData() {
      return [{
        total_users: 250,
      }];
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (! this.filter.organisationId) {
        this.data = CalculatorStat.demoData;
        this.requestUpdate();
        console.warn('Filters not set for the budget stat. Using demo data.');
        return;
      }
      if (this.data && ! refresh) return;

      return Task.run(async() => {
        this.data = await this.#statDomain.budget(this.filter.organisationId, {
          allocated: this.type === 'allocated',
        });
        this.requestUpdate();
      }, {
        global: false,
        ghost: this
      });
    }

    get value() {
      return null;
    }

    render() {
      return html`
        <flex-container>
          <flex-item class="col-6">
            <slider-group>
              <h3>
                Daily cost for absence
              </h3>
              <slider-container>
                <label>
                  &euro; 0
                </label>
                <range-slider
                  ${ref(this.#dailyCostRangeRef)}
                  @input="${(e) => this.dailyCost = e.target.value}"
                  theme="darken" min="0" max="${this.dailyCostUpperLimit}" value="${this.dailyCost}" step="" track-color="#f4f0ea"></range-slider>
                <label>
                  &euro; ${(new Intl.NumberFormat()).format(this.dailyCostUpperLimit)}
                </label>
              </slider-container>
              <slider-output>
                &euro; ${(new Intl.NumberFormat()).format(this.dailyCost)}
              </slider-output>
            </slider-group>
          </flex-item>

          <flex-item class="col-6">
            <slider-group>
              <h3>
                Set annual Qogni Budget
              </h3>
              <slider-container>
                <label>
                  &euro; 0
                </label>
                <range-slider
                  ${ref(this.#annualBudgetRangeRef)}
                  @input="${(e) => this.annualBudget = e.target.value}"
                  theme="darken" min="0" max="${this.annualBudgetUpperLimit}" value="${this.annualBudget}" step="" track-color="#f4f0ea"></range-slider>
                <label>
                  &euro; ${(new Intl.NumberFormat()).format(this.annualBudgetUpperLimit)}
                </label>
              </slider-container>
              <slider-output>
                &euro; ${(new Intl.NumberFormat()).format(this.annualBudget)}
              </slider-output>
            </slider-group>
          </flex-item>
        </flex-container>

        <flex-container>
          <flex-item class="col-3">
            <section class="card output-card red">
              <h5>Exposed Risk (&euro;)</h5>
              <output-value>
                &euro; ${this.exposedRisk ? (new Intl.NumberFormat()).format(this.exposedRisk) : nothing}
              </output-value>
            </section>
          </flex-item>
          <flex-item class="col-3">
            <section class="card output-card">
              <h5>ROI Programs (%)</h5>
              <output-value>
                ${this.roiPrograms ?? nothing}
              </output-value>
            </section>
          </flex-item>
          <flex-item class="col-3">
            <section class="card output-card">
              <h5>Payback Period (days)</h5>
              <output-value>
                ${this.paybackPeriod ?? nothing}
              </output-value>
            </section>
          </flex-item>
          <flex-item class="col-3">
            <section class="card output-card green">
              <h5>Business Case (ROI)</h5>
              <output-value>
                &euro; ${this.businessRoi ? (new Intl.NumberFormat()).format(this.businessRoi) : nothing}
              </output-value>
            </section>
          </flex-item>
        </flex-container>
      `;
    }
  }
);
